.upload-container.large .ant-upload,
.upload-container.large .ant-upload-list-item-container {
  width: 100% !important;
  height: 180px !important;
}

.upload-container.small .ant-upload,
.upload-container.small .ant-upload-list-item-container {
  width: 100% !important;
  height: 100px !important;
}
