.dots {
  bottom: -30px !important;
}
label {
  cursor: pointer;
}

.ant-carousel {
  height: 100% !important;
}
